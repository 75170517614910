import { SessionURLs } from '../assets/types';
import * as Icons from '../assets/images/session_icons';

interface Session {
  number: number;
  name: string;
  description: string;
  url: string;
  programId: string;
  icon: string;
}

type SessionsType = { [key in SessionURLs]: Session };

const sessions: SessionsType = {
  FF: {
    number: 1,
    name: 'Fact vs Fiction',
    description:
      'Take the depression fact vs. fiction quiz, learn about the Emotional Equation, and get a better understanding of your mood and symptoms.',
    url: 'FF',
    programId: 'coaktl1',
    icon: Icons.FFicon,
  },
  MB: {
    number: 2,
    name: 'Mood Boosters',
    description:
      'Discover quick, easy, fun ways to immediately shake up your mood when you’re feeling low.',
    url: 'MB',
    programId: '4mg17u3',
    icon: Icons.MBicon,
  },
  PA: {
    number: 3,
    name: 'Positive Achievements',
    description:
      'Take an assessment of the kinds of activities you enjoy most and make a plan to have more positive activities in your life.',
    url: 'PA',
    programId: '1m7wixe',
    icon: Icons.PAicon,
  },
  LIES: {
    number: 4,
    name: 'Trigger Plan & Exposure Ladder',
    description:
      'Take a quiz to sort out the truth from the lies depression tells and learn about a tool that will bring greater clarity to your own situation.',
    url: 'LIES',
    programId: '46zu9cx',
    icon: Icons.LIESicon,
  },
  IMJ: {
    number: 5,
    name: 'Emotional Equations',
    description:
      'Learn the surprising way emotions are formed and track your activities, thoughts, and mood to discover patterns in your own behaviors and emotions.',
    url: 'IMJ',
    programId: 'yvjaiso',
    icon: Icons.IMJicon,
  },
  SUCC: {
    number: 6,
    name: 'Goals & Successes',
    description:
      'Complete the “Goal Trainer” and explore new ways to extend the positive effects of good situations.',
    url: 'SUCC',
    programId: 'kr5zb0s',
    icon: Icons.SUCCicon,
  },
  TE: {
    number: 7,
    name: 'Recognizing Thought Errors',
    description: 'Learn about Thought Errors, a big contributor to low mood.',
    url: 'TE',
    programId: 'ytt8uzt',
    icon: Icons.TEicon,
  },
  STRU: {
    number: 8,
    name: 'Explaining Struggles',
    description:
      'Learn the unique way you handle negative situations and create solutions to some of the issues you’re currently working on.',
    url: 'STRU',
    programId: '5a14vrm',
    icon: Icons.STRUicon,
  },
  CTE: {
    number: 9,
    name: 'Confronting Thought Errors',
    description:
      'Explore why your Thought Errors are formed and take steps to reduce them in your life.',
    url: 'CTE',
    programId: 'l28kag6',
    icon: Icons.CTEicon,
  },
  DA: {
    number: 10,
    name: 'Understanding Core Beliefs',
    description:
      'Learn a special technique to uncover and address some of your most negatively influential beliefs about yourself, others, and the world.',
    url: 'DA',
    programId: 'qlyk6t8',
    icon: Icons.DAicon,
  },
  CONC: {
    number: 11,
    name: 'Conclusion',
    description:
      "Review the great work you've done in UpLift, prepare for the next steps, and receive a special closing gift.",
    url: 'CONC',
    programId: 'si43x52',
    icon: Icons.CONCicon,
  },
  REG: {
    number: 0,
    name: 'Introduction to UpLift',
    description:
      'Get an overview of UpLift, do an assessment of your mood, and get a first task to complete on your own.',
    url: 'REG',
    programId: 'nzg3ush',
    icon: Icons.REGicon,
  },
};

const numberToSession = (sess: number) => {
  let sessionName;
  switch (sess) {
    case 0:
      sessionName = 'REG';
      break;
    case 1:
      sessionName = 'FF';
      break;
    case 2:
      sessionName = 'MB';
      break;
    case 3:
      sessionName = 'PA';
      break;
    case 4:
      sessionName = 'LIES';
      break;
    case 5:
      sessionName = 'IMJ';
      break;
    case 6:
      sessionName = 'SUCC';
      break;
    case 7:
      sessionName = 'TE';
      break;
    case 8:
      sessionName = 'STRU';
      break;
    case 9:
      sessionName = 'CTE';
      break;
    case 10:
      sessionName = 'DA';
      break;
    case 11:
      sessionName = 'CONC';
      break;
    default:
      sessionName = 'REG';
      break;
  }
  return sessionName;
};

export { sessions, numberToSession };
