import {
  logIn,
  setNameAndEmail,
  setToken,
  setCurrentSession,
} from '../state/AppDataSlice';
import { setToken as setUserDataToken } from '../state/UserDataSlice';
import { UCS } from '../apis/UCS';
import '../assets/styles/App.css';

import { Dispatch } from 'redux';
import crypto from 'crypto';

interface LogInRequest {
  email: string;
  password: string;
}

const useLogIn = async (
  loginRequest: LogInRequest,
  dispatch: Dispatch<any>
) => {
  const authRequest = {
    email: loginRequest.email,
    password: loginRequest.password,
  };

  dispatch(
    setNameAndEmail({
      email: loginRequest.email,
    })
  );

  const result = await UCS.signIn(authRequest);
  if ('token' in result) {
    const token = crypto
      .createHash('sha256')
      .update(authRequest.email)
      .digest('hex');
    fetch('https://api.uplift.app/uplift/web_login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: authRequest.email,
        password: authRequest.password,
      }),
    });
    localStorage.setItem(
      'user',
      JSON.stringify({
        // name: authRequest.name,
        // email: authRequest.email,
        // password: authRequest.password,
        // token: result.token,
        token: token,
      })
    );
    dispatch(setToken(result.token));
    dispatch(setUserDataToken(result.token));
    dispatch(logIn());
    dispatch(setCurrentSession(result.currentSession));
    // setLoggedIn(true);
    return true;
  } else if (result.error === 'Invalid sign-in credentials.') {
    console.log('Sign-in Error: Invalid sign-in credentials.');
    return false;
  } else {
    console.log(`Sign-in Error: ${JSON.stringify(result.error)}`);
    return false;
  }
};

export default useLogIn;
