// Most of what's here is to harmonize the existing iOS structure with the web app structure, without changing the structures the iOS app depends on.

/**
 * Contains an array of missions for each session.
 */
export const missionsMap: string[][] = [
  // 13 = MJ, 14 = MB, 15 = PS, 16 = PA, 17 = TRIG, 19 = SUCC
  ['13', '13', '13'], // REG - These are hidden; included only as an error-catch.
  ['15', '24', '13'], // FF
  ['14', '19', '13'], // MB - mood booster
  ['19', '15', '13'], // PA - successes
  ['16', '14', '13'], // LIES - positive activities
  ['17', '16', '13'], // IMJ - trigger plan TODO: exposure for anxiety
  ['16', '19', '13'], // SUCC
  ['20', '14', '13'], // TE - goal trainer
  ['21', '16', '13'], // STRU - thought errors tool
  ['22', '15', '13'], // CTE - struggles tool
  ['21', '14', '13'], // DA
  ['23', '16', '13'], // CONC - DA tool
  ['16', '19', '13'], // ... figure this out when I can test it all.
];

// * Could convert this to a map, in which case it would be `let x = new Map; x.set(13, {programId: kalsd93u});` , etc.

// * Also seems like I should be able to combine these two types.

interface MissionIdLookup {
  [index: string]: { url: string; programId: string };
}

/**
 * This uses the id from the UCS Missions array's mission objects to look up a cleaner URL and the GT program ID. Ideally these would just be added to the UCS missions object at some point, but that may require adjustment of the UCS.
 */
export const missionLookupById: MissionIdLookup = {
  13: {
    // mood journal
    url: 'moodjournal',
    programId: 'nn9hadm',
  },
  14: {
    // mood boosters
    url: 'moodboosters',
    programId: 'grygegw',
  },
  15: {
    // problem solver
    url: 'problemsolver',
    programId: 'jhb80e1',
  },
  16: {
    // positive activities
    url: 'positiveactivities',
    programId: 'b23jgwl',
  },
  17: {
    // trigger plan
    url: 'triggers',
    programId: 'snbspud',
  },
  19: {
    // successes
    url: 'successes',
    programId: 'b1jlvh4',
  },
  20: {
    // goal trainer
    url: 'goaltrainer',
    programId: 'w6694ow',
  },
  21: {
    // thought errors
    url: 'thoughterrors',
    programId: 'exdou8i',
  },
  22: {
    // struggles
    url: 'struggles',
    programId: 'vj8m64v',
  },
  23: {
    // DA/Balanced Belief/core beliefs
    url: 'corebeliefs',
    programId: '5ecrokm',
  },
  24: {
    // Mission Statement
    url: 'missionstatement',
    programId: '1g4xe8q',
  },
  // 26: {
  //   // Depression Screening
  // url: '/bonus-PH9?workflow=bonus',
  // programId:
  // },
  // 27: {
  //   // Anxiety screening
  //   url: '/bonus-GAD7',
  //   programId:
  // },
  // 32: {
  //   // Mini Mood Boosters
  //   url: '/bonus-12-mini-mood-boosters?UpLift=yes',
  //   programId:
  // }
};

// ! Don't need url or programId here; get rid of.

interface MissionEventLookup {
  [index: string]: {
    id: number;
    url: string;
    programId: string;
  };
}

export const missionLookupByEvent: MissionEventLookup = {
  usedMJtool: {
    // mood journal

    id: 13,
    url: 'moodjournal',
    programId: 'nn9hadm',
  },
  usedMBtool: {
    // mood boosters

    id: 14,
    url: 'moodboosters',
    programId: 'grygegw',
  },
  usedPStool: {
    id: 15,
    url: 'problemsolver',
    programId: 'jhb80e1',
  },
  usedPAtool: {
    // positive activities

    id: 16,
    url: 'positiveactivities',
    programId: 'b23jgwl',
  },
  usedTRIGtool: {
    // trigger plan

    id: 17,
    url: 'triggers',
    programId: 'snbspud',
  },
  usedSUCCtool: {
    id: 19,
    url: 'successes',
    programId: 'b1jlvh4',
  },
  usedGoalTool: {
    // goal trainer

    id: 20,
    url: 'goaltrainer',
    programId: 'w6694ow',
  },
  usedTEtool: {
    // thought errors

    id: 21,
    url: 'thoughterrors',
    programId: 'exdou8i',
  },
  usedSTRUtool: {
    // struggles

    id: 22,
    url: 'struggles',
    programId: 'vj8m64v',
  },
  usedDAtool: {
    // DA/Balanced Belief/core beliefs

    id: 23,
    url: 'corebeliefs',
    programId: '5ecrokm',
  },
  'bonus-missionStatement': {
    // Mission Statement

    id: 24,
    url: 'missionstatement',
    programId: '1g4xe8q',
  },
};
