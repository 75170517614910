// *trigger: dailyMJsAssigned
// 	*send: {"dailyMJentriesAssigned" -> dailyMJentriesAssigned}

import { MissionObject } from '../apis/UCS';

/**
 * An object containing all possible user missions.
 * This is UCS-derived, and, for compatability with the iOS app, the field names should not be changed.
 */
const missions: MissionObject[] = [
  {
    id: 1,
    name: 'Introduction',
    istemplate: true,
    missiontype: 1,
    gemreward: 10,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'session1icon',
    url: '/REG',
    eventtocomplete: 'sess1Complete',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 2,
    name: 'Fact vs Fiction',
    istemplate: true,
    missiontype: 1,
    gemreward: 10,
    tasktotal: 5,
    taskscompleted: 0,
    icon: 'session2icon',
    url: '/FF',
    eventtocomplete: 'sess2Complete',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 3,
    name: 'Mood Boosters',
    istemplate: true,
    missiontype: 1,
    gemreward: 10,
    tasktotal: 5,
    taskscompleted: 0,
    icon: 'session3icon',
    url: '/MB',
    eventtocomplete: 'sess3Complete',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 4,
    name: 'Positive Activities',
    istemplate: true,
    missiontype: 1,
    gemreward: 10,
    tasktotal: 5,
    taskscompleted: 0,
    icon: 'session4icon',
    url: '/PA',
    eventtocomplete: 'sess4Complete',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 5,
    name: 'Facing Mood Triggers',
    istemplate: true,
    missiontype: 1,
    gemreward: 10,
    tasktotal: 5,
    taskscompleted: 0,
    icon: 'session5icon',
    url: '/LIES',
    eventtocomplete: 'sess5Complete',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 6,
    name: 'Emotional Equation',
    istemplate: true,
    missiontype: 1,
    gemreward: 10,
    tasktotal: 5,
    taskscompleted: 0,
    icon: 'session6icon',
    url: '/IMJ',
    eventtocomplete: 'sess6Complete',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 7,
    name: 'Goals & Successes',
    istemplate: true,
    missiontype: 1,
    gemreward: 10,
    tasktotal: 6,
    taskscompleted: 0,
    icon: 'session7icon',
    url: '/SUCC',
    eventtocomplete: 'sess7Complete',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 8,
    name: 'Recognizing Thought Errors',
    istemplate: true,
    missiontype: 1,
    gemreward: 10,
    tasktotal: 5,
    taskscompleted: 0,
    icon: 'session8icon',
    url: '/TE',
    eventtocomplete: 'sess8Complete',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 9,
    name: 'Explaining Struggles',
    istemplate: true,
    missiontype: 1,
    gemreward: 10,
    tasktotal: 4,
    taskscompleted: 0,
    icon: 'session9icon',
    url: '/STRU',
    eventtocomplete: 'sess9Complete',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 10,
    name: 'Confronting Thought Errors',
    istemplate: true,
    missiontype: 1,
    gemreward: 10,
    tasktotal: 5,
    taskscompleted: 0,
    icon: 'session10icon',
    url: '/CTE',
    eventtocomplete: 'sess10Complete',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 11,
    name: 'Understanding Core Beliefs',
    istemplate: true,
    missiontype: 1,
    gemreward: 10,
    tasktotal: 6,
    taskscompleted: 0,
    icon: 'session11icon',
    url: '/DA',
    eventtocomplete: 'sess11Complete',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 12,
    name: 'Conclusion',
    istemplate: true,
    missiontype: 1,
    gemreward: 10,
    tasktotal: 5,
    taskscompleted: 0,
    icon: 'session12icon',
    url: '/CONC',
    eventtocomplete: 'sess12Complete',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 13,
    name: 'Mood Journal',
    istemplate: true,
    missiontype: 3,
    gemreward: 1,
    tasktotal: 3,
    taskscompleted: 0,
    icon: 'moodjournalicon',
    url: '/moodjournal',
    eventtocomplete: 'usedMJtool',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 14,
    name: 'Mood Booster',
    istemplate: true,
    missiontype: 4,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'moodboostersicon',
    url: '/MB-Mood_Booster',
    eventtocomplete: 'usedMBtool',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 15,
    name: 'Problem Solver',
    istemplate: true,
    missiontype: 4,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'problemsolvericon',
    url: '/problem_solver',
    eventtocomplete: 'usedPStool',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 16,
    name: 'Positive Activity',
    istemplate: true,
    missiontype: 4,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'positiveactivitiesicon',
    url: '/PA-PAchallenge',
    eventtocomplete: 'usedPAtool',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 17,
    name: 'Trigger Plan',
    istemplate: true,
    missiontype: 4,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'triggericon',
    url: '/Triggers-Toolbox_shell',
    eventtocomplete: 'usedTRIGtool',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 18,
    name: 'Exposure Tool',
    istemplate: true,
    missiontype: 4,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'exposureicon',
    url: '/Exposure-Toolbox_shell',
    eventtocomplete: 'usedETtool',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 19,
    name: 'Successes',
    istemplate: true,
    missiontype: 4,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'successesicon',
    url: '/SUCC-SUCCchallenge',
    eventtocomplete: 'usedSUCCtool',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 20,
    name: 'Goal Trainer',
    istemplate: true,
    missiontype: 4,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'goaltrainericon',
    url: '/Goals',
    eventtocomplete: 'usedGoalTool',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 21,
    name: 'Thought Errors',
    istemplate: true,
    missiontype: 4,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'thoughterrorsicon',
    url: '/TE-Toolbox_shell',
    eventtocomplete: 'usedTEtool',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 22,
    name: 'Struggles',
    istemplate: true,
    missiontype: 4,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'strugglesicon',
    url: '/STRU-STRUchallenge',
    eventtocomplete: 'usedSTRUtool',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 23,
    name: 'Strengthen Balanced Belief',
    istemplate: true,
    missiontype: 4,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'corebeliefsicon',
    url: '/DA-DAchallenge',
    eventtocomplete: 'usedDAtool',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 24,
    name: 'Mission Statement',
    istemplate: true,
    missiontype: 5,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'bonusmissionsicon',
    url: '/bonus-mission_statement',
    eventtocomplete: 'bonus-missionStatement',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 25,
    name: 'Add a Friend',
    istemplate: true,
    missiontype: 5,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'bonusmissionsicon',
    url: '/bonus-add_friends?bonus=friends',
    eventtocomplete: 'bonus-addFriend',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 26,
    name: 'Depression Screening',
    istemplate: true,
    missiontype: 5,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'bonusmissionsicon',
    url: '/bonus-PHQ9?workflow=bonus',
    eventtocomplete: 'bonus-PHQ',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 27,
    name: 'Anxiety Screening',
    istemplate: true,
    missiontype: 5,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'bonusmissionsicon',
    url: '/bonus-GAD7',
    eventtocomplete: 'bonus-GAD',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 28,
    name: 'Send Feedback',
    istemplate: true,
    missiontype: 5,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'bonusmissionsicon',
    url: '/contribute',
    eventtocomplete: 'bonus-giveFeedback',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 29,
    name: '3 Deep Breaths',
    istemplate: true,
    missiontype: 5,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'bonusmissionsicon',
    url: '/bonus-3-breaths',
    eventtocomplete: 'bonus-3deepBreaths',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 30,
    name: 'Cute Animals',
    istemplate: true,
    missiontype: 5,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'bonusmissionsicon',
    url: '/bonus-cute-overload?asReward=yes&UpLift=yes',
    eventtocomplete: 'bonus-cuteAnimals',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 31,
    name: 'Letter to Yourself',
    istemplate: true,
    missiontype: 5,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'bonusmissionsicon',
    url: '/bonus-letter-to-self',
    eventtocomplete: 'bonus-letterToSelf',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 32,
    name: 'Mini Mood Boosters',
    istemplate: true,
    missiontype: 5,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'bonusmissionsicon',
    url: '/bonus-12-mini-mood-boosters?UpLift=yes',
    eventtocomplete: 'bonus-miniMoodBoosters',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 33,
    name: 'Feeling Loved',
    istemplate: true,
    missiontype: 5,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'bonusmissionsicon',
    url: '/bonus-love-potion?UpLift=yes',
    eventtocomplete: 'bonus-feelingLoved',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 34,
    name: 'Future Self',
    istemplate: true,
    missiontype: 5,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'bonusmissionsicon',
    url: '/bonus-future-self?UpLift=yes',
    eventtocomplete: 'bonus-futureSelf',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 35,
    name: 'Life-Improving Questions',
    istemplate: true,
    missiontype: 5,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'bonusmissionsicon',
    url: '/bonus-14-questions?UpLift=yes',
    eventtocomplete: 'bonus-14Questions',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 36,
    name: 'Expressive Writing',
    istemplate: true,
    missiontype: 5,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'bonusmissionsicon',
    url: '/bonus-brief-expressive-writing',
    eventtocomplete: 'bonus-expressiveWriting',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 37,
    name: 'Gratitude List',
    istemplate: true,
    missiontype: 5,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'bonusmissionsicon',
    url: '/bonus-gratitude-list',
    eventtocomplete: 'bonus-gratitudeList',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 38,
    name: '3 Good Things',
    istemplate: true,
    missiontype: 5,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'bonusmissionsicon',
    url: '/bonus-3-good-things',
    eventtocomplete: 'bonus-3goodThings',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 39,
    name: 'Fact vs Fiction',
    istemplate: false,
    missiontype: 1,
    gemreward: 10,
    tasktotal: 5,
    taskscompleted: 0,
    icon: 'session2icon',
    url: '/FF',
    eventtocomplete: 'sess2Complete',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 40,
    name: 'Mood Journal',
    istemplate: false,
    missiontype: 3,
    gemreward: 1,
    tasktotal: 3,
    taskscompleted: 0,
    icon: 'moodjournalicon',
    url: '/moodjournal',
    eventtocomplete: 'usedMJtool',
    iscompleted: false,
    scheduledfor: null,
  },
  {
    id: 41,
    name: 'Mission Statement',
    istemplate: false,
    missiontype: 5,
    gemreward: 1,
    tasktotal: 1,
    taskscompleted: 0,
    icon: 'bonusmissionsicon',
    url: '/bonus-mission_statement',
    eventtocomplete: 'bonus-missionStatement',
    iscompleted: false,
    scheduledfor: null,
  },
];

interface IndexedMissionsObjectType {
  [index: string]: MissionObject;
}

/**
 * Convert the UCS missions array to an indexed object for more reliable lookup.
 * @param missionsArray - Full Missions array as received by UCS.
 * @returns - Missions object indexed by mission id.
 */
const missionsToObject = (missionsArray: MissionObject[]) => {
  let missionsBucket: IndexedMissionsObjectType = {};
  for (let mission of missionsArray) {
    missionsBucket[mission.id] = mission;
  }
  return missionsBucket;
};

export default missions;

export { missionsToObject };
