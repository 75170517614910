import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * This is data just used by the app. It is not uploaded to the UCS at any point. It's mostly used during the login process.
 */
export const AppDataSlice = createSlice({
  name: 'AppData',
  initialState: {
    loggedIn: false,
    name: '',
    email: '',
    token: '',
    currentSession: 0,
  },
  reducers: {
    logIn: (state) => {
      state.loggedIn = true;
    },
    setNameAndEmail: (
      state,
      action: PayloadAction<{ name?: string; email: string }>
    ) => {
      if (action.payload.name) {
        state.name = action.payload.name;
      }
      state.email = action.payload.email;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setCurrentSession: (state, action: PayloadAction<number>) => {
      state.currentSession = action.payload;
    },
  },
});

export const {
  logIn,
  setNameAndEmail,
  setToken,
  setCurrentSession,
} = AppDataSlice.actions;

export default AppDataSlice.reducer;
