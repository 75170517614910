import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Indexable, SessionURLs } from '../assets/types';
import { UCSDownloadResponse } from '../apis/UCS';
import { RootState } from './store';
import { sessions } from '../models/sessionsModel';
import {
  missionLookupByEvent,
  missionLookupById,
} from '../models/missionsModel_helpers';
import { HRAnswersObject } from '../views/Questionnaire';
import missions, { missionsToObject } from '../models/missionsModel';

// TODO: Clean up. Remove reducer logic to separate modules. Look at combining reducers into a single reducer with a switch statement.

const initialState: UCSDownloadResponse = {
  gtData: {
    id: 1,
    gtdatastring: {
      name: '',
      platform: 'unset',
      currentSession: 'Initial',
      moodJournalEntryDates: [],
      moodJournalEmoEntries: [],
      mjRollovers: [],
      dailyMJentriesAssigned: undefined,
      sessionsCompleted: undefined,
      hrAssociateStatus: '',
      hrYearsAt: '',
      hrAge: '',
      hrGender: '',
      hrEthnicity: '',
      hasSeenHRModal: false,
    },
  },
  rewards: [],
  scoreEntries: [],
  missions: missions,
  user: {
    currentSession: 0,
    hasActiveSubscription: false,
    paidThroughDate: null,
    mailchimpUserId: null,
  },
  token: '',
};

export const UserDataSlice = createSlice({
  name: 'UserData',
  initialState,
  reducers: {
    /**
     * Pushes state downloaded from UCS to Redux store
     * @param state - current state
     * @param action - action with payload of UCS data object download
     */
    // ? Consider removing logic to modules in separate folder. This is getting big.
    pushAllState: (state, action: PayloadAction<UCSDownloadResponse>) => {
      state.gtData = action.payload.gtData;
      state.rewards = action.payload.rewards;
      state.scoreEntries = action.payload.scoreEntries;
      // Catch UCS sending this back as lowercase and re-assign to camel-case property
      if (action.payload.missions[12].scheduledfor) {
        action.payload.missions[12].scheduledFor =
          action.payload.missions[12].scheduledfor;
        delete action.payload.missions[12].scheduledfor;
      }
      if (action.payload.missions.length > 0) {
        state.missions = action.payload.missions;
      }
      // Ditto
      if (action.payload.user.paidthroughdate) {
        action.payload.user.paidThroughDate =
          action.payload.user.paidthroughdate;
        delete action.payload.user.paidthroughdate;
      }
      state.user = action.payload.user;

      state.token = action.payload.token;
    },
    addGTData: (state, action: PayloadAction<Indexable>) => {
      try {
        const fields = Object.keys(action.payload);
        for (let field of fields) {
          state.gtData.gtdatastring[field] = action.payload[field];
        }
      } catch (e) {
        console.log(
          `Error getting data object keys. Error is: \n ${e}\n The payload you tried to pass in was: \n ${JSON.stringify(
            action.payload
          )}`
        );
      }
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    completeMission: (state, action: PayloadAction<string>) => {
      if (action.payload === 'usedMJtool') {
        let now = new Date();
        let dateScheduled = new Date();
        if (state.missions[12].scheduledFor) {
          dateScheduled = new Date(state.missions[12].scheduledFor);
        } else {
          state.missions[12].scheduledFor = now.toISOString();
        }
        if (now.getTime() - dateScheduled.getTime() > 1000 * 60 * 5) {
          state.missions[12].scheduledFor = new Date().toISOString();
        }
        state.missions[12].taskscompleted++;
      } else {
        state.missions[
          missionLookupByEvent[action.payload].id - 1
        ].iscompleted = true;
      }
    },
    /**
     * Set all missions to not-yet-done. Called on receipt of sessionsCompleted event.
     * @param state
     * @param action - Placeholder
     */
    clearMissions: (state, action: PayloadAction<string>) => {
      for (let i in missionLookupById) {
        state.missions[Number(i)].iscompleted = false;
      }
      state.missions[12].taskscompleted = 0;
    },

    /**
     * Checking Mood Journals and removing checkmarks if 12 hours have passed.
     * @param state
     * @param action - Placeholder
     */
    checkMJMissions: (state, action: PayloadAction<string>) => {
      // TODO: Not using the action...send without? Or combine with others similar into a single reducer, and distinguish by payload? I think that's more what I'm supposed to be doing.
      // TODO: try sending this without a payload.
      let now = new Date();
      let dateScheduled = new Date();
      if (state.missions[12].scheduledfor) {
        dateScheduled = new Date(state.missions[12].scheduledfor);
      }
      if (now.getTime() - dateScheduled.getTime() > 1000 * 60 * 60 * 12) {
        state.missions[12].taskscompleted = 0;
      }
    },
    addHRBlockData: (state, action: PayloadAction<HRAnswersObject>) => {
      state.gtData.gtdatastring.hrAssociateStatus =
        action.payload.associateType;
      state.gtData.gtdatastring.hrYearsAt = action.payload.yearsAtHR;
      state.gtData.gtdatastring.hrAge = action.payload.age;
      state.gtData.gtdatastring.hrGender = action.payload.gender;
      state.gtData.gtdatastring.hrEthnicity = action.payload.ethnicity;
      state.user.hasActiveSubscription = action.payload.hasActiveSubscription;
      state.user.paidThroughDate = action.payload.paidThroughDate;
    },
    sawIntroModal: (state, action: PayloadAction<boolean>) => {
      state.gtData.gtdatastring.hasSeenIntroModal = action.payload;
    },
    addMailchimpId: (state, action: PayloadAction<string>) => {
      state.user.mailchimpUserId = action.payload;
    },
    // // TODO: Enable generics -> Why doesn't this work?
    // addUserData: (state, action: PayloadAction<{category: UCSUserObjectProperties, value: string}>) => { // type PayloadAction via utility type
    //   state.user[action.payload.category] = action.payload.value;
    // },
    // addGTData: (state, action: PayloadAction<string>) => { // ditto
    // //   state.gtData.gtdatastring[action.payload]
    // // }
  },
});

export const {
  pushAllState,
  addGTData,
  setToken,
  completeMission,
  clearMissions,
  checkMJMissions,
  addHRBlockData,
  sawIntroModal,
  addMailchimpId,
} = UserDataSlice.actions; // Note that, in a somewhat opaque process, this exports action creators.

// selectors---------------

/**
 * Use for uploading user data.
 * @param state
 */
const getUserData = (state: RootState) => state.UserData;
/**
 * Use for passing data into GT.
 * @param state
 */
const getGTData = (state: RootState) => state.UserData.gtData.gtdatastring;
const getUserChildObject = (state: RootState) => state.UserData.user;
const getMJData = (state: RootState) => {
  const mjDataObject = {
    gtDates: state.UserData.gtData.gtdatastring.moodJournalEntryDates,
    scores: state.UserData.gtData.gtdatastring.moodJournalEmoEntries,
    rollovers: state.UserData.gtData.gtdatastring.mjRollovers,
  };
  return mjDataObject;
};
const getDailyMJEntriesAssigned = (state: RootState) =>
  state.UserData.gtData.gtdatastring.dailyMJentriesAssigned;
// ! Currently unused. If ultimately don't use, remove this selector.
const getCurrentSession = (state: RootState) => {
  // if necessary, convert to take a number/string flag
  const session = state.UserData.gtData.gtdatastring.currentSession;
  if (session === 'Initial') {
    return 100;
  }
  let sessionNumber = 0;
  let sessionKeys = Object.keys(sessions) as SessionURLs[];
  sessionKeys.forEach((sess: SessionURLs) => {
    if (sessions[sess].url === session) {
      sessionNumber = sessions[sess].number;
    }
  });
  return sessionNumber;
};
const getSessionsCompleted = (state: RootState) => {
  const sessionsCompleted =
    state.UserData.gtData.gtdatastring.sessionsCompleted;
  if (sessionsCompleted === undefined) {
    return 0;
  } else {
    return sessionsCompleted + 1;
  }
};
const getMissions = (state: RootState) => {
  const reduxMissions = state.UserData.missions;
  // this is all to address a as-yet mysterious bug where the missions array appears to be either truncated or disordered.
  if (reduxMissions.length !== missions.length) {
    return missionsToObject(missions);
  }
  const missionsObject = missionsToObject(reduxMissions);
  return missionsObject;
};

export {
  getUserData,
  getGTData,
  getUserChildObject,
  getMJData,
  getCurrentSession,
  getSessionsCompleted,
  getMissions,
  getDailyMJEntriesAssigned,
};

export default UserDataSlice.reducer;
