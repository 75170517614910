import { configureStore } from '@reduxjs/toolkit';
import UserDataReducer from './UserDataSlice';
import AppDataReducer from './AppDataSlice';

export const store = configureStore({
  reducer: {
    UserData: UserDataReducer,
    AppData: AppDataReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
