import { useDispatch, useSelector } from 'react-redux';
import { setToken } from '../state/UserDataSlice';
import ReactGA from 'react-ga';
import { RootState } from '../state/store';
import { useHistory } from 'react-router';
import React, { SetStateAction, useEffect } from 'react';

export const useDispatchToken = () => {
  const dispatch = useDispatch();
  const dispatchToken = (newToken: string) => {
    dispatch(setToken(newToken));
  };
  return dispatchToken;
};

export const useAnalyticsEvent = (action: string) => {
  useEffect(() => {
    ReactGA.event({
      category: 'full-web',
      action: action,
    });
  });
};

/**
 * Check if the paywall should be shown, and display paywall or allow route.
 * @param active - Unlocked?
 * @param sessNumber - Session number. REG=0, FF=1.
 * @param route - Router route.
 * @param paywallToggle - State hook to toggle paywall.
 * @returns - click handler.
 */
export const usePaywallCheck = (
  active: boolean,
  sessNumber: number,
  route: string,
  paywallToggle: React.Dispatch<SetStateAction<boolean>>
) => {
  const isPaid = useSelector(
    (state: RootState) => state.UserData.user.paidThroughDate
  );
  const history = useHistory();

  let paid: boolean;
  if (isPaid) {
    paid = new Date(isPaid) > new Date();
  } else {
    paid = false;
  }

  const handleClick = () => {
    console.log(
      `Paywall debug info:\nActive: ${active}\nsessNumber: ${sessNumber}\npaid: ${paid}\n======\n`
    );
    if ((active && sessNumber < 2) || (active && paid)) {
      history.push(route);
      console.log('Paid & Active, or < sess 2');
    } else if (paid || sessNumber < 2) {
      console.log('Not yet unlocked');
    } else {
      console.log('Show paywall');
      paywallToggle(true);
    }
  };

  return handleClick;
};
