import React from 'react';
import {
  Button,
  Typography,
  Grid,
  GridProps,
  TypographyProps,
  styled,
} from '@material-ui/core';
import { backgroundColor, primaryText } from '../assets/styles/main_theme';

import { widthFull, maxWidthFull } from './component_styles';

interface MainGridProps extends GridProps {
  noBackground?: boolean;
}

function MainGrid(props: MainGridProps) {
  let background = backgroundColor;
  if (props.noBackground) {
    background = 'transparent';
  }

  return (
    <Grid
      container
      direction='column'
      wrap='nowrap'
      alignItems='center'
      style={{
        minHeight: '100vh',
        padding: '30px 5vw 100px',
        background: background,
        overflow: 'scroll',
      }}
    >
      {props.children}
    </Grid>
  );
}

// ----------------------------

const StyledButton = styled(Button)({
  color: 'white',
  borderRadius: 20,
});

// ----------------------------
interface TitleProps extends TypographyProps {
  text: string;
}

const Title = (props: TitleProps) => {
  return (
    <Typography
      variant='h5'
      align='left'
      style={{
        margin: '.4em 0 .7em',
        width: widthFull,
        maxWidth: maxWidthFull,
      }}
    >
      {props.text}
    </Typography>
  );
};

// ------------------------------------------------

const CustomTypography = styled(Typography)({
  color: primaryText,
});

export { MainGrid, Title, CustomTypography, StyledButton };
