import { makeStyles } from '@material-ui/core';
import { darkText } from '../assets/styles/main_theme';

export const widthFull = '80vw';
export const maxWidthFull = '320px';

const componentStyles = makeStyles({
  card: {
    width: widthFull,
    maxWidth: maxWidthFull,
    marginBottom: '3vh',
    cursor: 'pointer',
    overflow: 'visible',
    borderRadius: 11,
  },
  toolCard: {
    width: '40vw',
    height: '40vw',
    maxWidth: 150,
    maxHeight: 120,
    margin: '1em .5em',
  },
  sessionNumber: {
    borderRadius: 20,
    fontSize: '1em',
    backgroundColor: 'white',
    color: darkText,
    padding: '.1em .4em',
  },
  missionMain: {
    height: '30vw',
    display: 'flex',
  },
  missionListItems: {
    paddingLeft: '2rem',
  },
  checkbox: {
    color: '#0000004f',
  },
  greyedOut: {
    filter: 'grayscale(1)',
    cursor: 'not-allowed',
    '& *': {
      cursor: 'not-allowed',
    },
  },
  active: {
    filter: 'unset',
    cursor: 'inherit',
  },
  padded: {
    '& *': {
      marginTop: 10,
    },
  },
});

export { componentStyles };
