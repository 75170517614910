import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
//! This is experimental, to try to suppress deprecation error for <Dialog> component. Keep an eye on it.

// Card style values ------------

export const buttonColorGradient = '82';
export const buttonColorGradientMiddle = 'e8';

const defaultButtonColor = '#8571FF';
const sessionColorWheel = [
  '#A74BC5',
  '#9642DA',
  '#7B4ADC',
  '#6641E3',
  '#5356FF',
  '#0075FF',
  '#16b4ff',
  '#17bcd0',
  '#20bf86',
  '#2cb12e',
  '#a3ce25',
];

// Colors -------------------

const primaryGreen = '#09a0aa';

export const actionOrange = '#f2994a';

export const primaryColor = primaryGreen;
export const backgroundColor =
  'linear-gradient(to bottom, #ffffff, #fefefe, #fefefe, #f6f6f6)';

// Typography
export const darkText = '#000000';
export const primaryText = '#ffffff';

// Themes ------------------

const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryGreen,
    },
    text: {
      primary: primaryText,
      secondary: 'white',
      // why doesn't this change the default text color?
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
  },
  props: {
    MuiTypography: {
      variantMapping: {
        // h1: 'h2',
        // h2: 'h2',
        // h3: 'h2',
        // h4: 'h2',
        h5: 'h1',
        // h6: 'h2',
        subtitle1: 'h2',
        // subtitle2: 'h2',
        // body1: 'span',
        // body2: 'span',
      },
    },
  },
});

export { theme, defaultButtonColor, sessionColorWheel };
